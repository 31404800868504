$(
	function() {
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.setBackUrls();
		app.attachSvgEvents();
		app.addCoverSpan();
		app.assignProductOver();
		app.saveProductListInfo();
		app.makeSocialButtons({
			marginLeft: -2
		});
	}
);

var app = {
	_vars: {
		v: 198
	}
};